var $ = window.jQuery;
console.log('reg page loaded');


$(".filter-lazy-load").on("click", "a", function (e) {
            e.preventDefault();
            var active_cat = $(this).data("category");
            var active_label = $(this).data("label");
            var post_type = $(this).parents(".filter-lazy-load").data("post-type");
            var category_slug = $(this).parents(".filter-lazy-load").data("category-slug");
    $("#registryFilters .active-item").html(active_label);


/*    Ajax Lazy Load Elements*/
            $.ajax({
                url: ajaxlazyload.ajaxurl,
                type: "post",
                data: {
                    action: "ajax_lazyload",
                    query_vars: ajaxlazyload.query_vars,
                    active_cat: active_cat,
                    post_type: post_type,
                    category_slug: category_slug,

                },
                beforeSend: function () {
                    $(".filter-lazy-load-content").find(".registry-item").remove();
                    $(".filter-lazy-load-content").append("<div class=\"spinner active\"><span class=\"sr-only\">Loading</span><i class=\"fal fa-sync fa-spin fa-2x \"></i></div>");



                },
                success: function (html) {

                    $(".filter-lazy-load-content").find(".spinner").remove();
                    $(".filter-lazy-load-content").append(html);




                },
                error: function () {
                    $("body").addClass("ajax-fail");
                }


            });

    });
